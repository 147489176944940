import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('datatable',{attrs:{"headers":_vm.headers,"pagination":_vm.pagination,"datos":_vm.alumnosLocal,"itemsPerPage":_vm.alumnosLocal.length,"totalItems":_vm.alumnosLocal.length,"hideSearch":"","hidePagination":""},scopedSlots:_vm._u([{key:"item-avatar",fn:function(ref){
var value = ref.value;
return [_c(VAvatar,{attrs:{"color":"primary","size":"30"}},[(!value)?_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-account")]):_c('img',{attrs:{"src":(_vm.imageURL + "/avatar/" + value),"alt":"UserImage"}})],1)]}},{key:"item-calificacion",fn:function(ref){
var item = ref.item;
return [(item.calificaciones && item.calificaciones.length)?_c('div',[_c(VChip,{staticClass:"mr-3",attrs:{"label":""}},[_c('span',{staticClass:"text-button"},[_vm._v(_vm._s(parseInt(item.calificaciones[0].calificacion)))])]),_c(VBtn,{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.openDetalles(item)}}},[_c('span',[_vm._v("Ver intentos")])])],1):_c('div',[_c(VChip,{attrs:{"color":"amber darken-2","dark":"","label":""}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-alert")]),_c('span',{staticClass:"text-button"},[_vm._v("Sin presentar")])],1)],1)]}}])}),(_vm.showDetalles)?_c('detalles-calificacion',{attrs:{"show":_vm.showDetalles,"calificacion":_vm.calificacionSeleccionada},on:{"cancelar":function($event){return _vm.closeDetalles()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }